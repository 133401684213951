.Card__container {
  perspective: 120rem;
  height: 100%;
  width: 100%;
}

.Card {
  cursor: pointer;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  position: relative;
  transition: all 2s ease;
  transform-style: preserve-3d;
  /* box-shadow: 0 3px 3px rgba(0, 0, 0, 0.4); */
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.8);
}

.Card__content {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
}

.Card__container:hover {
  outline: 10px solid red;
}

.Card__depth {
  transform: translateZ(100px) scale(0.98);
  perspective: inherit;
  background-color: rgba(0, 0, 0, 0.7);
}

.Card-front {
  transform-style: preserve-3d;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #000000;
}

.Card-front:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
}

.Card:hover,
.Card:focus {
  transform: rotateX(-180deg);
}

.Card:hover .Card-front:after,
.Card:focus .Card-front:after {
  background-color: transparent;
}

.Card-back {
  transform: rotateX(180deg);
  transform-style: preserve-3d;
  background-color: #000000;
  /* background-image: linear-gradient(to right bottom, #348cf1, #3282fa); */
  height: 100%;
  text-align: center;
  margin-bottom: 1rem;
}

.Card__title {
  font-size: 1.6rem;
  color: #ffffff;
  margin-top: 2rem;
}

.Card__desc {
  font-size: 1.2rem;
  color: #ffffff;
  margin: 1rem auto;
}
