.TextareaWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  /* margin-right: 5rem; */
  width: 80%;
}

.Label {
  color: #ffffff;
}

.LabelDark {
  color: #000000;
}

.Label,
.LabelDark {
  margin-right: 2rem;
  font-size: 2rem;
  width: 10rem;
  text-align: center;
}

.Textarea {
  width: 90%;
  height: 10rem;
  border: 1px solid #000000;
  border-radius: 1rem;
  padding: 0 2rem;
  font-size: 1.7rem;
  outline: none;
}


@media screen and (max-width: 1336px) {
  .TextareaWrapper {
    flex-direction: column;
    margin: 0;
  }
}