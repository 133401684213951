.Contact {
  width: 50%;
  height: 75%;
  border: 2px solid #000000;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.7);
}

@media screen and (max-width: 1136px) {
  .Contact {
    width: 75%;
  }
}

@media screen and (max-width: 636px) {
  .Contact {
    width: 95%;
  }
}

