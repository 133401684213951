.Progress {
  width: 90%;
  margin: 0 auto;
}

.Progress__label {
  font-size: 1.4rem;
  font-weight: 600;
}

.Bar {
  width: 100%;
  height: 1.2rem;
  background-color: #ffffff;
  margin: 0.5rem auto;
  position: relative;
}
.Inner__bar {
  position: absolute;
  top: 0;
  left: 0;
  /* min-width: 30%; */
  height: 1.2rem;
  animation: widthAnimate 1.5s ease-in-out;
}

.Progress__amount {
  font-size: 1.4rem;
  font-weight: 600;
  position: absolute;
  bottom: 1.7rem;
  right: 0;
}

@keyframes widthAnimate {
  0% {
    width: 30%;
    opacity: 0.5;
    background-color: red;
  }

  100% {
    max-width: 200%;
    opacity: 1;
  }
}
