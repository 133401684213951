.CardIcons {
  width: 100%;
  padding: 1rem 0;
  display: flex;
  justify-content: space-around;
  transition: all 0.5s ease-out;
}

.Card__icon {
  font-size: 1.6rem;
}

#Card-js {
  color: #f8fb2e;
}
#Card-css3 {
  color: #1100f8;
}
#Card-react {
  color: #2e87fb;
}
#Card-sass {
  color: #fb2e79;
}
