.Modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 1;
}
.Modal-container {
  width: 75%;
  height: 75%;
  margin: 3rem auto 0;
  z-index: 2;
}

.Modal {
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.Modal::-webkit-scrollbar {
  width: 12px;
}

.Modal::-webkit-scrollbar-track {
  background: rgb(0, 0, 0); /* color of the tracking area */
}

.Modal::-webkit-scrollbar-thumb {
  background-color: #0047ff; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
}

.Modal__links {
  display: flex;
}

/* modal */
.Modal__video {
  width: 100%;
  height: auto;
  margin: auto;
  position: relative;
}

.Modal__scroll {
  font-size: 2.4rem;
  font-weight: 600;
  letter-spacing: 4px;
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 45%;
  z-index: 10;
  color: #ffffff;
}


@media screen and (max-width: 1336px) {
  .Modal-container {
    width: 90%;
  }
}

@media screen and (max-width: 636px) {
  .Modal__links {
    flex-direction: column;
  }
  .Modal__scroll {
    display: none;
  }
}
