@import url(https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@300;400;500;600;700&display=swap);
html {
  box-sizing: border-box;
}

body {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  font-family: "Cormorant Garamond", serif;
}


h1,
p,
h2 {
  margin: 0;
  padding: 0;
}

ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: #ffffff;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.Nav {
  width: 20%;
  height: 100%;
  background-color: #000000;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  position: -webkit-sticky;
  position: sticky;
  -webkit-sticky: sticky;
  top: 0;
  left: 0;
  transition: all 0.5s ease-in;
}

.Nav__logo {
  width: 80%;
  margin: 2rem auto 0;
  font-size: 3rem;
  font-weight: 700;
}

.Nav__list {
  /* height: 10rem; */
  width: 80%;
  margin: 0 auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  /* justify-content: space-evenly; */
  font-size: 2rem;
  font-weight: 200;
}
.Nav__list-item {
  margin: 1rem 0;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.Nav__social {
  width: 80%;
  margin: 2rem auto;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}
.Nav__social-icon {
  margin: 1rem 0;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 2rem;
  position: relative;
}

.Nav__social-icon.github {
  width: 100%;
  text-align: center;
  font-size: 1.5rem;
}

.Nav__close {
  z-index: 40;
  font-size: 3rem;
  color: #2514be;
  position: absolute;
  top: 5%;
  right: 5%;
  display: none;
}

.Nav.Nav__view {
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
}

@media screen and (max-width: 1136px) {
  /* .Nav {
    position: sticky;
    -webkit-sticky: sticky;
    top: 0;
    left: 0;
  } */
}

@media screen and (max-width: 836px) {
  .Nav__logo {
    margin: 1rem auto 0;
    font-size: 2.5rem;
  }
}

@media screen and (max-width: 636px) {
  .Nav__open,
  .Nav__close {
    display: block;
  }

  .Nav {
    position: fixed;
    top: 0;
    left: -100%;
    width: 100%;
    z-index: 100;
  }
  .Nav__logo {
    width: 80%;
    margin: 2rem auto 0;
    font-size: 3rem;
    font-weight: 700;
  }
}

@media screen and (max-width: 500px) {
  .Nav__logo {
    width: 50%;
    margin: 2rem 0 0;
    font-size: 3rem;
    font-weight: 700;
  }
}

.Confirmation {
  width: 80%;
  height: 80%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  text-align: center;
}

.Confirmation__title {
  font-size: 3rem;
  font-weight: 600;
}

.Confirmation__desc {
  font-size: 2.4rem;
}

@media screen and (max-width: 1136px) {
  .Confirmation__title {
    font-size: 2rem;
  }

  .Confirmation__desc {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 636px) {
  .Confirmation__title {
    font-size: 1.7rem;
  }

  .Confirmation__desc {
    font-size: 1.3rem;
  }
}

.Home__main {
  width: 80%;
  display: -webkit-flex;
  display: flex;
}

.Intro {
  height: 100%;
  width: 50%;
  display: -webkit-flex;
  display: flex;
  /* flex-direction: column; */
  text-align: center;
  -webkit-align-items: center;
          align-items: center;
  overflow: hidden;
}

.perspective {
  min-width: 100%;
  min-height: 100%;
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  transition: all 0.5s ease-in-out;
}

.Intro:hover .Intro__b,
.Intro:hover .Intro__m,
.Intro:hover .perspective {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
}

.Intro__m,
.Intro__b {
  width: 100%;
  min-width: 100%;
  min-height: 100%;
  height: 100%;
  font-size: 50rem;
  transition: all 0.5s ease-in-out;
}

.Intro__b {
  color: #ffffff;
  background-color: #920001;
}

.Intro__m {
  color: transparent;
  background-image: url(/static/media/whirlpool.c1ae5a52.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  -webkit-background-clip: text;
          background-clip: text;
}

.Project__a,
.Project__b,
.Project__c,
.Project__d {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 50%;
  height: 100%;
  position: relative;
  overflow: hidden;
  padding: 2rem 0;
}

.project__in {
  position: absolute;
  bottom: 20%;
  border-radius: 50rem;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.8);
}

.Project__a {
  background-color: #002769;
}

.Project__a .project__in {
  background-color: #eacc00;
  right: -40%;
  width: 25rem;
  height: 25rem;
}

.Project__b {
  background-color: #6a0001;
}

.Project__b .project__in {
  background-color: #1a4cc0;
  /* right: -82.5%; */
  left: -20%;
  bottom: 10%;
  width: 50rem;
  height: 50rem;
}

.Project__c {
  background-color: #c86401;
}

.Project__c .project__in {
  background-color: #d4c2a0;
  bottom: 10%;
  right: -30%;
  width: 40rem;
  height: 40rem;
}

.Project__d {
  background-color: #039595;
}

.Project__d .project__in {
  background-color: #1f1e1d;
  right: -50%;
  width: 20rem;
  height: 20rem;
}

.Project__title {
  font-size: 3rem;
  font-weight: 600;
  color: #ffffff6f;
  text-transform: uppercase;
  z-index: 10;
}

.Project__title:hover,
.Project__title:focus {
  color: #ffffff;
  cursor: pointer;
}

.Project__More {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  font-size: 3rem;
  font-weight: 700;
  color: #ffffff;
  text-transform: uppercase;
  text-decoration: underline;
  z-index: 10;
}

@media screen and (max-width: 1500px) {
  .Intro__m,
  .Intro__b {
    font-size: 40rem;
  }
}

@media screen and (max-width: 1336px) {
  .Intro__m,
  .Intro__b {
    font-size: 30rem;
    line-height: 2;
  }
}

@media screen and (max-width: 1136px) {
  .Intro__m,
  .Intro__b {
    font-size: 25rem;
    line-height: 2.2;
  }
  .Project__title,
  .Project__More {
    font-size: 2rem;
  }
}

@media screen and (max-width: 836px) {
  .Intro__m,
  .Intro__b {
    font-size: 20rem;
    line-height: 2.8;
  }
  .Project__title,
  .Project__More {
    font-size: 1.7rem;
  }
}

@media screen and (max-width: 636px) {
  .Home__main {
    width: 100%;
    -webkit-flex-direction: column;
            flex-direction: column;
  }

  .Intro {
    width: 100%;
    height: 50%;
  }

  .Intro__m,
  .Intro__b {
    font-size: 25rem;
    line-height: 1.2;
  }
}

@media screen and (max-width: 500px) {
  .Intro__m,
  .Intro__b {
    font-size: 20rem;
    line-height: 1.5;
  }
}

.Progress {
  width: 90%;
  margin: 0 auto;
}

.Progress__label {
  font-size: 1.4rem;
  font-weight: 600;
}

.Bar {
  width: 100%;
  height: 1.2rem;
  background-color: #ffffff;
  margin: 0.5rem auto;
  position: relative;
}
.Inner__bar {
  position: absolute;
  top: 0;
  left: 0;
  /* min-width: 30%; */
  height: 1.2rem;
  -webkit-animation: widthAnimate 1.5s ease-in-out;
          animation: widthAnimate 1.5s ease-in-out;
}

.Progress__amount {
  font-size: 1.4rem;
  font-weight: 600;
  position: absolute;
  bottom: 1.7rem;
  right: 0;
}

@-webkit-keyframes widthAnimate {
  0% {
    width: 30%;
    opacity: 0.5;
    background-color: red;
  }

  100% {
    max-width: 200%;
    opacity: 1;
  }
}

@keyframes widthAnimate {
  0% {
    width: 30%;
    opacity: 0.5;
    background-color: red;
  }

  100% {
    max-width: 200%;
    opacity: 1;
  }
}

.About {
  width: 80%;
  height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.LeftSide,
.Desc {
  margin: auto;
  width: 40%;
  height: 80%;
}

.Photo {
  height: 50%;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.Photo img {
  height: 100%;
  width: auto;
}

.Skills {
  width: 100%;
  height: 50%;
  margin: 0 auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  background-color: #d4d4d4;
}

/* Right Side of Page */
.Desc__about {
  height: 50%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  font-size: 1.2rem;
}

.Desc__interests {
  height: 50%;
}

.Desc__interests-title {
  font-size: 2.4rem;
  font-weight: 600;
}

.Desc__interests-item {
  margin: 1rem auto;
  font-size: 1.6rem;
}

@media screen and (max-width: 1500px) {
}

@media screen and (max-width: 1336px) {
  .Desc {
    width: 50%;
    height: 90%;
  }

  .Desc__interests-item {
    font-size: 1.3rem;
  }
}

@media screen and (max-width: 1136px) {
  .About {
    -webkit-flex-direction: column;
            flex-direction: column;
    margin: 4rem 0;
  }

  .LeftSide,
  .Desc {
    width: 90%;
    height: auto;
  }

  .Photo {
    height: auto;
    width: 100%;
  }

  .Photo img {
    height: 25rem;
    width: auto;
  }

  .Skills {
    width: 75%;
    height: auto;
    margin: 2rem auto;
    background-color: #d4d4d494;
  }

  .Desc {
    text-align: center;
  }

  .Desc__about {
    height: auto;
    font-size: 1.4rem;
    margin: 2rem auto;
  }

  .Desc__interests {
    height: auto;
    margin: 2rem auto;
  }
}

@media screen and (max-width: 836px) {
  .Photo img {
    height: 25em;
    width: auto;
  }

  .Skills {
    width: 95%;
  }
}

@media screen and (max-width: 636px) {
  .About {
    width: 100%;
    height: auto;
  }
}

@media screen and (max-width: 500px) {
  .Photo img {
    height: 15rem;
    width: auto;
  }
}


.Project {
  width: 50%;
  height: auto;
  /* height: 100%; */
  /* margin: 1rem auto; */
  /* border: 1px solid #000000; */
}

.Project__card-skills {
  display: -webkit-flex;
  display: flex;
  margin-bottom: 1rem;
}

.Project__card-skills p {
  width: 45%;
  text-align: center;
}

.Project__card {
  width: 16.5rem;
  height: 16.5rem;
  margin: 3rem auto 3rem;
}

@media screen and (max-width: 1136px) {
  .Project__card {
    width: 15.5rem;
    height: 15.5rem;
  }
}

@media screen and (max-width: 836px) {
  .Project__card {
    width: 14rem;
    height: 14rem;
  }
}

@media screen and (max-width: 636px) {
  .Project {
    width: 75%;
    height: 22rem;
  }
  .Project__card {
    width: 17.5rem;
    height: 17.5rem;
  }
  .Project__card-skills p {
    width: 100%;
  }
}

@media screen and (max-width: 500px) {
  .Project__card {
    width: 14rem;
    height: 14rem;
  }
}

.Card__container {
  -webkit-perspective: 120rem;
          perspective: 120rem;
  height: 100%;
  width: 100%;
}

.Card {
  cursor: pointer;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  position: relative;
  transition: all 2s ease;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  /* box-shadow: 0 3px 3px rgba(0, 0, 0, 0.4); */
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.8);
}

.Card__content {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
}

.Card__container:hover {
  outline: 10px solid red;
}

.Card__depth {
  -webkit-transform: translateZ(100px) scale(0.98);
          transform: translateZ(100px) scale(0.98);
  -webkit-perspective: inherit;
          perspective: inherit;
  background-color: rgba(0, 0, 0, 0.7);
}

.Card-front {
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #000000;
}

.Card-front:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
}

.Card:hover,
.Card:focus {
  -webkit-transform: rotateX(-180deg);
          transform: rotateX(-180deg);
}

.Card:hover .Card-front:after,
.Card:focus .Card-front:after {
  background-color: transparent;
}

.Card-back {
  -webkit-transform: rotateX(180deg);
          transform: rotateX(180deg);
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  background-color: #000000;
  /* background-image: linear-gradient(to right bottom, #348cf1, #3282fa); */
  height: 100%;
  text-align: center;
  margin-bottom: 1rem;
}

.Card__title {
  font-size: 1.6rem;
  color: #ffffff;
  margin-top: 2rem;
}

.Card__desc {
  font-size: 1.2rem;
  color: #ffffff;
  margin: 1rem auto;
}

.CardIcons {
  width: 100%;
  padding: 1rem 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  transition: all 0.5s ease-out;
}

.Card__icon {
  font-size: 1.6rem;
}

#Card-js {
  color: #f8fb2e;
}
#Card-css3 {
  color: #1100f8;
}
#Card-react {
  color: #2e87fb;
}
#Card-sass {
  color: #fb2e79;
}

.ZoomCard {
  cursor: pointer;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  background-position: center;
  background-size: 17.5rem;
  background-repeat: no-repeat;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.8);
  transition: all 1s ease-in-out;
}

.ZoomCard:hover {
  outline: 10px solid red;
}

.ZoomCard__depth {
  height: 100%;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  visibility: hidden;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  background-color: rgba(69, 89, 219, 0.788);
  transition: all 0.3s ease-in 1.2s;
}

.ZoomCard__title {
  font-size: 1.6rem;
  color: #ffffff;
}
.ZoomCard__desc {
  font-size: 1.2rem;
  margin: 1rem auto;
  color: #ffffff;
}

.ZoomCard:hover,
.ZoomCard:focus {
  background-size: 50rem;
}

.ZoomCard:hover .CardIcons {
  display: none;
}

.ZoomCard:hover .ZoomCard__depth {
  /* display: flex; */
  visibility: visible;
}

.ZoomCard .CardIcons {
  /* margin: 0 1rem; */
  background-color: rgba(0, 0, 0, 0.8);
}

.Site {
  color: #ffffff;
  font-size: 2rem;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 50%;
  padding: 1rem 0;
  transition: -webkit-transform 0.3s ease-in;
  transition: transform 0.3s ease-in;
  transition: transform 0.3s ease-in, -webkit-transform 0.3s ease-in;
}
.Site__icon {
  margin-right: 2rem;
}

.Site:hover {
  -webkit-transform: scale(1.01);
          transform: scale(1.01);
  box-shadow: 0 5px 8px rgba(88, 88, 88, 0.7);
}

@media screen and (max-width: 636px) {
  .Site {
    width: 100%;
  }
}

.Feature {
  width: 90%;
  height: 100%;
  margin: 5% auto;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
}

.Feature.small {
  height: auto;
  margin: 5% auto;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.reverse {
  -webkit-flex-direction: row-reverse;
          flex-direction: row-reverse;
}

.Feature__info {
  height: 100%;
  width: 30%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
}

.Feature__desc {
  font-size: 2rem;
  margin: 1rem auto;
  text-align: center;
}

.Feature__img {
  height: auto;
  width: 65%;
  margin: auto 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  overflow: hidden;
}

.Feature__img img {
  width: auto;
  max-width: 100%;
  max-height: 40rem;
}

@media screen and (max-width: 1336px) {
  .Feature__desc {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 836px) {
  .Feature {
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    height: auto;
  }

  .Feature.small {
    height: auto;
  }

  .Feature__info {
    width: 100%;
    margin: 2rem auto;
  }

  .Feature__img {
    width: 90%;
  }
}



/* .Video {
} */

.Modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 1;
}
.Modal-container {
  width: 75%;
  height: 75%;
  margin: 3rem auto 0;
  z-index: 2;
}

.Modal {
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.Modal::-webkit-scrollbar {
  width: 12px;
}

.Modal::-webkit-scrollbar-track {
  background: rgb(0, 0, 0); /* color of the tracking area */
}

.Modal::-webkit-scrollbar-thumb {
  background-color: #0047ff; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
}

.Modal__links {
  display: -webkit-flex;
  display: flex;
}

/* modal */
.Modal__video {
  width: 100%;
  height: auto;
  margin: auto;
  position: relative;
}

.Modal__scroll {
  font-size: 2.4rem;
  font-weight: 600;
  letter-spacing: 4px;
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 45%;
  z-index: 10;
  color: #ffffff;
}


@media screen and (max-width: 1336px) {
  .Modal-container {
    width: 90%;
  }
}

@media screen and (max-width: 636px) {
  .Modal__links {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
  .Modal__scroll {
    display: none;
  }
}

.Projects {
  width: 80%;
  height: 100%;
  max-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.Project__set {
  width: 90%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}



@media screen and (max-width: 1136px) {
  .Projects {
    height: 100%;
  }
  .Project__set {
    width: 100%;
    -webkit-align-items: center;
            align-items: center;
  }
}

@media screen and (max-width: 636px) {
  .Projects {
    width: 100%;
  }
  .Project__set {
    margin-top: 5rem;
  }
}



.InputWrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-right: 5rem;
}

.Label {
  color: #ffffff;
}

.LabelDark {
  color: #000000;
}

.Label,
.LabelDark {
  margin-right: 2rem;
  font-size: 2.4rem;
  width: 10rem;
  text-align: center;
}

.Input {
  width: 25rem;
  border: 1px solid #000000;
  border-radius: 1rem;
  padding: 0.5rem;
  font-size: 1.7rem;
  outline: none;
}



@media screen and (max-width: 1336px) {
  .InputWrapper {
    -webkit-flex-direction: column;
            flex-direction: column;
    margin: 0;
  }
}

@media screen and (max-width: 500px) {
  .Input {
    width: 16rem;
  }
}

.TextareaWrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 2rem;
  /* margin-right: 5rem; */
  width: 80%;
}

.Label {
  color: #ffffff;
}

.LabelDark {
  color: #000000;
}

.Label,
.LabelDark {
  margin-right: 2rem;
  font-size: 2rem;
  width: 10rem;
  text-align: center;
}

.Textarea {
  width: 90%;
  height: 10rem;
  border: 1px solid #000000;
  border-radius: 1rem;
  padding: 0 2rem;
  font-size: 1.7rem;
  outline: none;
}


@media screen and (max-width: 1336px) {
  .TextareaWrapper {
    -webkit-flex-direction: column;
            flex-direction: column;
    margin: 0;
  }
}
.Button {
  font-family: "Cormorant Garamond", serif;
  outline: none;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 2px;
  background-color: #c01818;
  border: none;
  max-width: 10rem;
  max-height: 4rem;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding: 1rem 1rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.Button:hover,
.Button:focus {
  background-color: #e7bdbd;
}

.Error {
  color: rgb(229, 0, 0);
  font-size: 1.8rem;
  font-weight: 600;
  /* margin-top: -3rem; */
}

.Contact {
  width: 50%;
  height: 75%;
  border: 2px solid #000000;
  margin: auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  -webkit-align-items: center;
          align-items: center;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.7);
}

@media screen and (max-width: 1136px) {
  .Contact {
    width: 75%;
  }
}

@media screen and (max-width: 636px) {
  .Contact {
    width: 95%;
  }
}


.App {
  width: 100%;
  height: 100vh;
  display: -webkit-flex;
  display: flex;
  overflow: auto;
  position: relative;
}

.Nav__open {
  z-index: 40;
  font-size: 3rem;
  color: #2514be;
  position: absolute;
  top: 5%;
  left: 5%;
  display: none;
}

@media screen and (max-width: 636px) {
  .Nav__open {
    display: block;
    cursor: pointer;
  }
}

