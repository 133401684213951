.InputWrapper {
  display: flex;
  align-items: center;
  margin-right: 5rem;
}

.Label {
  color: #ffffff;
}

.LabelDark {
  color: #000000;
}

.Label,
.LabelDark {
  margin-right: 2rem;
  font-size: 2.4rem;
  width: 10rem;
  text-align: center;
}

.Input {
  width: 25rem;
  border: 1px solid #000000;
  border-radius: 1rem;
  padding: 0.5rem;
  font-size: 1.7rem;
  outline: none;
}



@media screen and (max-width: 1336px) {
  .InputWrapper {
    flex-direction: column;
    margin: 0;
  }
}

@media screen and (max-width: 500px) {
  .Input {
    width: 16rem;
  }
}
