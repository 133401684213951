.Home__main {
  width: 80%;
  display: flex;
}

.Intro {
  height: 100%;
  width: 50%;
  display: flex;
  /* flex-direction: column; */
  text-align: center;
  align-items: center;
  overflow: hidden;
}

.perspective {
  min-width: 100%;
  min-height: 100%;
  width: 100%;
  height: 100%;
  display: flex;
  transition: all 0.5s ease-in-out;
}

.Intro:hover .Intro__b,
.Intro:hover .Intro__m,
.Intro:hover .perspective {
  transform: translateX(-100%);
}

.Intro__m,
.Intro__b {
  width: 100%;
  min-width: 100%;
  min-height: 100%;
  height: 100%;
  font-size: 50rem;
  transition: all 0.5s ease-in-out;
}

.Intro__b {
  color: #ffffff;
  background-color: #920001;
}

.Intro__m {
  color: transparent;
  background-image: url("../../assets//img/whirlpool.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-clip: text;
}

.Project__a,
.Project__b,
.Project__c,
.Project__d {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 100%;
  position: relative;
  overflow: hidden;
  padding: 2rem 0;
}

.project__in {
  position: absolute;
  bottom: 20%;
  border-radius: 50rem;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.8);
}

.Project__a {
  background-color: #002769;
}

.Project__a .project__in {
  background-color: #eacc00;
  right: -40%;
  width: 25rem;
  height: 25rem;
}

.Project__b {
  background-color: #6a0001;
}

.Project__b .project__in {
  background-color: #1a4cc0;
  /* right: -82.5%; */
  left: -20%;
  bottom: 10%;
  width: 50rem;
  height: 50rem;
}

.Project__c {
  background-color: #c86401;
}

.Project__c .project__in {
  background-color: #d4c2a0;
  bottom: 10%;
  right: -30%;
  width: 40rem;
  height: 40rem;
}

.Project__d {
  background-color: #039595;
}

.Project__d .project__in {
  background-color: #1f1e1d;
  right: -50%;
  width: 20rem;
  height: 20rem;
}

.Project__title {
  font-size: 3rem;
  font-weight: 600;
  color: #ffffff6f;
  text-transform: uppercase;
  z-index: 10;
}

.Project__title:hover,
.Project__title:focus {
  color: #ffffff;
  cursor: pointer;
}

.Project__More {
  height: fit-content;
  font-size: 3rem;
  font-weight: 700;
  color: #ffffff;
  text-transform: uppercase;
  text-decoration: underline;
  z-index: 10;
}

@media screen and (max-width: 1500px) {
  .Intro__m,
  .Intro__b {
    font-size: 40rem;
  }
}

@media screen and (max-width: 1336px) {
  .Intro__m,
  .Intro__b {
    font-size: 30rem;
    line-height: 2;
  }
}

@media screen and (max-width: 1136px) {
  .Intro__m,
  .Intro__b {
    font-size: 25rem;
    line-height: 2.2;
  }
  .Project__title,
  .Project__More {
    font-size: 2rem;
  }
}

@media screen and (max-width: 836px) {
  .Intro__m,
  .Intro__b {
    font-size: 20rem;
    line-height: 2.8;
  }
  .Project__title,
  .Project__More {
    font-size: 1.7rem;
  }
}

@media screen and (max-width: 636px) {
  .Home__main {
    width: 100%;
    flex-direction: column;
  }

  .Intro {
    width: 100%;
    height: 50%;
  }

  .Intro__m,
  .Intro__b {
    font-size: 25rem;
    line-height: 1.2;
  }
}

@media screen and (max-width: 500px) {
  .Intro__m,
  .Intro__b {
    font-size: 20rem;
    line-height: 1.5;
  }
}
