.Site {
  color: #ffffff;
  font-size: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  padding: 1rem 0;
  transition: transform 0.3s ease-in;
}
.Site__icon {
  margin-right: 2rem;
}

.Site:hover {
  transform: scale(1.01);
  box-shadow: 0 5px 8px rgba(88, 88, 88, 0.7);
}

@media screen and (max-width: 636px) {
  .Site {
    width: 100%;
  }
}
