.Nav {
  width: 20%;
  height: 100%;
  background-color: #000000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  -webkit-sticky: sticky;
  top: 0;
  left: 0;
  transition: all 0.5s ease-in;
}

.Nav__logo {
  width: 80%;
  margin: 2rem auto 0;
  font-size: 3rem;
  font-weight: 700;
}

.Nav__list {
  /* height: 10rem; */
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  /* justify-content: space-evenly; */
  font-size: 2rem;
  font-weight: 200;
}
.Nav__list-item {
  margin: 1rem 0;
  width: fit-content;
}

.Nav__social {
  width: 80%;
  margin: 2rem auto;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.Nav__social-icon {
  margin: 1rem 0;
  width: fit-content;
  font-size: 2rem;
  position: relative;
}

.Nav__social-icon.github {
  width: 100%;
  text-align: center;
  font-size: 1.5rem;
}

.Nav__close {
  z-index: 40;
  font-size: 3rem;
  color: #2514be;
  position: absolute;
  top: 5%;
  right: 5%;
  display: none;
}

.Nav.Nav__view {
  transform: translateX(100%);
}

@media screen and (max-width: 1136px) {
  /* .Nav {
    position: sticky;
    -webkit-sticky: sticky;
    top: 0;
    left: 0;
  } */
}

@media screen and (max-width: 836px) {
  .Nav__logo {
    margin: 1rem auto 0;
    font-size: 2.5rem;
  }
}

@media screen and (max-width: 636px) {
  .Nav__open,
  .Nav__close {
    display: block;
  }

  .Nav {
    position: fixed;
    top: 0;
    left: -100%;
    width: 100%;
    z-index: 100;
  }
  .Nav__logo {
    width: 80%;
    margin: 2rem auto 0;
    font-size: 3rem;
    font-weight: 700;
  }
}

@media screen and (max-width: 500px) {
  .Nav__logo {
    width: 50%;
    margin: 2rem 0 0;
    font-size: 3rem;
    font-weight: 700;
  }
}
