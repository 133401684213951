.About {
  width: 80%;
  height: 100vh;
  display: flex;
  align-items: center;
}

.LeftSide,
.Desc {
  margin: auto;
  width: 40%;
  height: 80%;
}

.Photo {
  height: 50%;
  width: 100%;
  display: flex;
  justify-content: center;
}

.Photo img {
  height: 100%;
  width: auto;
}

.Skills {
  width: 100%;
  height: 50%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-color: #d4d4d4;
}

/* Right Side of Page */
.Desc__about {
  height: 50%;
  display: flex;
  align-items: center;
  font-size: 1.2rem;
}

.Desc__interests {
  height: 50%;
}

.Desc__interests-title {
  font-size: 2.4rem;
  font-weight: 600;
}

.Desc__interests-item {
  margin: 1rem auto;
  font-size: 1.6rem;
}

@media screen and (max-width: 1500px) {
}

@media screen and (max-width: 1336px) {
  .Desc {
    width: 50%;
    height: 90%;
  }

  .Desc__interests-item {
    font-size: 1.3rem;
  }
}

@media screen and (max-width: 1136px) {
  .About {
    flex-direction: column;
    margin: 4rem 0;
  }

  .LeftSide,
  .Desc {
    width: 90%;
    height: auto;
  }

  .Photo {
    height: auto;
    width: 100%;
  }

  .Photo img {
    height: 25rem;
    width: auto;
  }

  .Skills {
    width: 75%;
    height: auto;
    margin: 2rem auto;
    background-color: #d4d4d494;
  }

  .Desc {
    text-align: center;
  }

  .Desc__about {
    height: auto;
    font-size: 1.4rem;
    margin: 2rem auto;
  }

  .Desc__interests {
    height: auto;
    margin: 2rem auto;
  }
}

@media screen and (max-width: 836px) {
  .Photo img {
    height: 25em;
    width: auto;
  }

  .Skills {
    width: 95%;
  }
}

@media screen and (max-width: 636px) {
  .About {
    width: 100%;
    height: auto;
  }
}

@media screen and (max-width: 500px) {
  .Photo img {
    height: 15rem;
    width: auto;
  }
}

