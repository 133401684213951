.Projects {
  width: 80%;
  height: 100%;
  max-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Project__set {
  width: 90%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
}



@media screen and (max-width: 1136px) {
  .Projects {
    height: 100%;
  }
  .Project__set {
    width: 100%;
    align-items: center;
  }
}

@media screen and (max-width: 636px) {
  .Projects {
    width: 100%;
  }
  .Project__set {
    margin-top: 5rem;
  }
}


