.Feature {
  width: 90%;
  height: 100%;
  margin: 5% auto;
  display: flex;
  justify-content: space-evenly;
}

.Feature.small {
  height: auto;
  margin: 5% auto;
  display: flex;
  align-items: center;
}

.reverse {
  flex-direction: row-reverse;
}

.Feature__info {
  height: 100%;
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Feature__desc {
  font-size: 2rem;
  margin: 1rem auto;
  text-align: center;
}

.Feature__img {
  height: auto;
  width: 65%;
  margin: auto 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.Feature__img img {
  width: auto;
  max-width: 100%;
  max-height: 40rem;
}

@media screen and (max-width: 1336px) {
  .Feature__desc {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 836px) {
  .Feature {
    flex-direction: column;
    align-items: center;
    height: auto;
  }

  .Feature.small {
    height: auto;
  }

  .Feature__info {
    width: 100%;
    margin: 2rem auto;
  }

  .Feature__img {
    width: 90%;
  }
}


